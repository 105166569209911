<template>
  <div class="my-5 mr-5">
    <p class="mb-8">You can upgrade or downgrade anytime during the 30 day trial.</p>
    <pricing-model
      v-if="price_models"
      :models="price_models"
      @onSelect="selectedModel = $event"
    ></pricing-model>
    <div class="plan_buttons">
      <bid-link
        :router-link="false"
        href-link="https://bidbrain.com/pricing"
        secondary
        class="compare_btn"
        target="_blank"
      >
        <template #text> Compare plan features </template>
        <template #icon>
          <ic-link />
        </template>
      </bid-link>
      <the-button
        v-if="isNewStore"
        @click.stop="updateStoreSubscription"
        :disabled="!selectedModel || loading"
        id="subscribe_next"
        inner-class="w-40"
        primary
      >
        Next
      </the-button>
      <the-button
        v-else
        @click.stop="$emit('updateStep', { step, index })"
        inner-class="w-40"
        primary
      >
        Next
      </the-button>
    </div>
  </div>
</template>

<script>
import PricingModel from '@/components/onboard-components/Subscription/PricingModel'
import CardForm from '@/components/account/CardForm'
import IcLink from 'vue-material-design-icons/OpenInNew'
import IcClose from 'vue-material-design-icons/Close'
import BidLink from '@/components/base/BidLink'
import { mapActions, mapGetters } from 'vuex'
import SubscriptionBadge from '@/components/subscriptions/SubscriptionBadge'
import BaseAlert from '@/components/alert/BaseAlert'

export default {
  name: 'Subscription',
  components: { BaseAlert, SubscriptionBadge, BidLink, CardForm, PricingModel, IcLink, IcClose },
  props: {
    step: {
      type: Object,
    },
    index: {
      type: Number,
    },
    checkIfLastStep: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedModel: null,
      price_models: null,
      loading: false,
    }
  },
  async mounted() {
    await this.loadSubscriptionsIfNeeded()
    this.price_models = this.subscriptions.filter(sub => sub.slug !== 'enterprise')
    this.price_models = this.price_models.sort((a, b) => b.name.localeCompare(a.name))
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
      subscriptions: 'subscription/getSubscriptions',
    }),
    isNewStore() {
      return localStorage.getItem(this.store.id)
    },
  },
  methods: {
    ...mapActions({
      loadSubscriptionsIfNeeded: 'subscription/loadSubscriptionsIfNeeded',
      updateStore: 'store/updateStore',
      loadStore: 'store/loadStoreDetails',
    }),
    async updateStoreSubscription(e) {
      if (!this.selectedModel) return

      this.loading = true
      await this.updateStore({
        storeId: this.store.id,
        data: { subscription_id: this.selectedModel.id },
      })
      this.$gtm.trackEvent({
        event: 'onboarding_subscription',
        subscription_type: this.selectedModel.slug,
        event_source_content: e.target.innerText,
        event_source: 'onboarding',
        project: 'console',
      })
      this.loading = false
      localStorage.removeItem(this.store.id)
      this.$emit('updateStep', { step: this.step, index: this.index })
    },
  },
}
</script>

<style scoped lang="scss">
.plan_buttons {
  @apply flex flex-wrap;
  .compare_btn {
    @apply mr-8 mb-4;
    @screen s {
      @apply mb-0;
    }
  }
}
</style>
