<template>
  <div class="wrapper">
    <div
      v-for="model in models"
      @click="selectModelOnClick(model)"
      :class="`pricing ${model.slug}`"
    >
      <div class="pricing-container">
        <div class="pricing-title">
          <span>{{ model.name }}</span>
          <base-check-box
            :input-id="model.slug"
            :input-name="model.slug"
            :value="model === selectedModel"
            circle
          />
        </div>

        <p>{{ model.description.title }}</p>

        <span
          class="h3"
          v-if="store.currency_id === 112"
        >
          {{ `${model.pricing.license_fee.SEK} SEK` }}</span
        >
        <span
          class="h3"
          v-else
          >{{ `€${model.pricing.license_fee.EUR}` }}</span
        >

        <p
          class="m-0"
          v-if="store.currency_id === 112"
        >
          {{ model.pricing.readable.SEK }}
        </p>
        <p
          class="m-0"
          v-else
        >
          {{ model.pricing.readable.EUR }}
        </p>
      </div>

      <p
        v-if="model.slug !== 'free'"
        class="pricing-footer"
      >
        <ic-timer />
        <span class="ml-2 uppercase">30 day free trial</span>
      </p>
    </div>
  </div>
</template>

<script>
import BaseCheckBox from '@/components/input/base/BaseCheckBox'
import IcTimer from 'vue-material-design-icons/Timer'
import { mapGetters } from 'vuex'

export default {
  name: 'PricingModel',
  components: { BaseCheckBox, IcTimer },
  props: {
    models: {
      type: Array,
    },
  },
  data() {
    return {
      selectedModel: null,
    }
  },
  beforeMount() {
    const slug = this.store?.subscription_slug || null

    if (slug === 'enterprise' || !slug) this.selectedModel = null
    else this.selectedModel = this.getSubscriptionBySlug(slug)

    this.$emit('onSelect', this.selectedModel)
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
      getSubscriptionBySlug: 'subscription/getSubscriptionBySlug',
    }),
  },
  methods: {
    selectModelOnClick(model) {
      this.selectedModel = model
      this.$emit('onSelect', model)
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  @apply grid grid-cols-1 gap-4 mb-4 auto-rows-max mb-8;
  width: fit-content;

  .pricing {
    @apply flex flex-col justify-between bg-white cursor-pointer;
    position: relative;
    z-index: 0;
    border-radius: 1rem;

    &:last-child {
      height: fit-content;
    }

    &-container {
      @apply p-6;
      border-radius: 1rem;
    }

    &.pro {
      & .pricing-title > span {
        font-size: 20px;
        font-weight: 700;
        background: linear-gradient(to top, #6e19f0, #39b579);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        padding: 2px;
        border-radius: 15px;
        background: linear-gradient(to top, $bb-neon-purple, $bb-dull-green);
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }

    &.pro_plus {
      & .pricing-title > span {
        font-size: 20px;
        font-weight: 700;
        background: linear-gradient(to top, $bb-neon-purple, $bb-neon-pink);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        padding: 2px;
        border-radius: 15px;
        background: linear-gradient(to top, $bb-neon-purple, $bb-neon-pink);
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }

    &.free {
      @apply border border-bb-dark-grey;
      border-width: 2px;
      border-radius: 15px;
      height: fit-content;

      & .pricing-title > span {
        font-size: 20px;
        font-weight: 700;
      }
    }

    &-title {
      @apply flex w-full justify-between mb-3;
    }

    &-footer {
      @apply bg-bb-neon-purple text-white m-0 text-center p-3 flex justify-center;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }

  @screen md {
    @apply grid-cols-2;
    .pricing {
      max-width: 14rem;
    }
  }

  @screen lg {
    @apply grid-cols-3;
    .pricing {
      max-width: 14rem;
    }
  }
}
</style>
