<template>
  <span
    :class="classes"
    class="base p4 capitalize"
    >{{ text }}</span
  >
</template>

<script>
const TEXT = {
  free: 'Free',
  pro: 'Pro',
  pro_plus: 'Pro+',
}

export default {
  name: 'subscription-badge',
  props: {
    slug: {
      type: String,
      required: true,
      validator: val => ['free', 'pro', 'pro_plus'].includes(val),
    },
  },
  computed: {
    text() {
      return TEXT[this.slug]
    },
    classes() {
      return this.slug
    },
  },
}
</script>

<style lang="scss" scoped>
.base {
  @apply rounded-lg px-2 text-white flex justify-center items-center;
  width: 36px;
  height: 24px;
}

.free {
  background-color: #fff;
  color: #000;
  border: #000 1px solid;
}

.pro {
  background: linear-gradient(204.06deg, rgba(0, 255, 133, 0.7) 19.97%, rgba(47, 211, 133, 0) 94.25%), $bb-neon-purple;
}

.pro_plus {
  background: linear-gradient(204.06deg, rgba(240, 75, 165, 0.7) 5.67%, rgba(240, 75, 165, 0) 94.25%), $bb-neon-purple;
}
</style>
